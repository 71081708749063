body {
  margin: 0;
  font-family: "Space Mono", monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
    

h1 span {
  padding: 5px;
  border-bottom: 3px solid rgb(27, 216, 210);
  /* border-top: 3px solid blueviolet; */
  line-height: .75em;
  mix-blend-mode: overlay;
  color: hsla(189, 37%, 93%, 0.926);
}