* {
  box-sizing: border-box;
}

body {
  background: radial-gradient(ellipse at top, #410741, transparent),
    radial-gradient(ellipse at left, #211322, transparent);
  background-size: cover;
  background-color: #140423;
}

.App {
  min-height: 100vh;
  text-align: center;
}

.user-header {
  border-radius: 100px;
  margin: 0.7vw;
  padding: 0.7vw;
  padding-right: 2vw;
  width: 97vw;
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
}

.user-header div {
  text-align: right;
  color: white;
}

.disconnect-wallet-button {
  margin-left: 10px;
  border-radius: 100px;
  font-size: 12px;
  background: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  width: 36px;
  height: 36px;
}

.container {
  padding: 0 30px 0 30px;
}

[data-solana-wallet-connected="true"] {
  .container {
    height: 100vh;
    padding: 30px;
    padding-top: 90px;
  }
  .centered-container {
    height: 100px;
    display: grid;
    place-content: center;
    margin-top: 20vh;
    transition: 1s;
  }
  .bouncy {
    transform: translateY(0px) scale(1);
    transition: transform 1s ease-out;
  }
}

.centered-container {
  width: 99vw;
  height: 100vh;
  display: grid;
  place-content: center;
  margin-top: 0;
  transition: 1s;
}

.connected-container {
  padding-top: 30px;
}

.header {
  margin: 0;
  font-size: 50px;
  font-weight: bold;
  color: white;
}

.sub-text {
  font-size: 25px;
  color: white;
}

.gradient-text {
  background: -webkit-linear-gradient(left, #60c657 30%, #35aee2 60%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.cta-button {
  height: 45px;
  border: 0;
  width: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: white;
}

.connect-wallet-button {
  background: -webkit-linear-gradient(left, #60c657, #35aee2);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  border-radius: 100px;
}

.submit-gif-button {
  background: -webkit-linear-gradient(left, #4e44ce, #35aee2);
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
}

#submit-gif {
  position: relative;
  border: 3px solid hsl(337deg, 97%, 25%);
  border-radius: 10px;
  .cancel {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 100px;
    width: 42px;
    height: 42px;
    background: none;
    border: 2px solid hsl(337deg, 97%, 25%);
    cursor: pointer
  }
}

.tip-button {
  padding: 10px 10px;
  background: transparent;
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  border: 3px solid #44ce9e;
}

.tip-button:hover {
  background: transparent;
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  border: 3px solid #44ce9e;
}

.love-button {
  padding: 10px 10px;
  background: transparent;
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  border: 3px solid #ce4444;
}

.love-button:hover {
  background: transparent;
  background-size: 200% 200%;
  animation: gradient-animation 4s ease infinite;
  border: 3px solid #ce4444;
}

.votes-value {
  place-content: center;
  align-self: center;
  justify-self: flex-end;
  font-size: 1em;
}

.title-value {
  place-content: center;
  align-self: center;
  justify-self: flex-start;
  font-size: 2em;
}

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding-bottom: 45px;
}

.twitter-logo {
  width: 35px;
  height: 35px;
}

.footer-text {
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.gif-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(512px, 1fr));
  grid-gap: 1.5rem;
  margin: 0;
  padding: 0;
}

.gif-grid .gif-item {
  display: flex;
  flex-direction: column;
  position: relative;
  align-self: center;
  mix-blend-mode: screen;
}

.gif-item img {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  object-fit: cover;
}

.gif-item button.add-gif {
  width: 100%;
  height: 400px;
  border-radius: 10px;
  object-fit: cover;
}

.connected-container input[type="text"] {
  display: inline-block;
  color: white;
  padding: 10px 20px;
  /* width: 390px; */
  height: 60px;
  font-size: 16px;
  box-sizing: border-box;
  background-color: rgb(14 14 14);
  border: none;
  border-radius: 10px;
}

.connected-container button {
  height: 50px;
}

.gif-item:hover .gif-controls {
  opacity: 1;
  transition: opacity 0.5s;
}

.gif-controls {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  place-content: flex-end;
  gap: 8px;
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: 10px;
  color: white;
  opacity: 0;
  transition: opacity 0.5s;
  backdrop-filter: blur(10px);
}

.gif-controls button {
  font-size: 1.6rem;
  line-height: 0;
}

.bounce {
  animation-name: bouncey;
  animation-duration: 0.8s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  display: inline-block;
  transform-origin: center center;
  text-align: center;
}

.bouncy {
  transform: translateY(40px) scale(2);
  transition: transform 1s ease-out;
  height: 100px;
}

@keyframes bouncey {
  0% {
    transform: translateY(1px) rotate(-1deg);
  }
  50% {
    transform: translateY(-1px) rotate(1deg);
  }
  100% {
    transform: translateY(1px) rotate(-1deg);
  }
}

.gif-submit-form {
  display: grid;
  place-content: center;
  justify-content: center;
  gap: 16px;
  height: 400px;
  background: -linear-gradient(left, #18062b 30%, #340853 60%);
}

.add-gif {
  background-color: transparent;
  border: 3px solid hsl(337, 97%, 25%);
  outline: none;
  color: hsl(337, 97%, 25%);
  font-size: 3rem;
  cursor: button;
  transition: background-color 0.5s, color 0.3s;
}

.add-gif:hover {
  background-color: transparent;
  border: 3px solid #ce4444;
  outline: none;
  color: #ce4444;
  font-size: 3rem;
  cursor: pointer;
  background-color: hsla(337, 97%, 25%, 0.2);
  transition: background-color 0.5s, color 0.3s;
}

#overlay {
  pointer-events: none;
  z-index: 1000;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 100vh;
  display: grid;
  place-content: center;
  background: rgba(0, 0, 0, 0);
  transition: 0.5s;
}

dialog {
  background: none;
  border: none;
  opacity: 0;
  transition: 0.5s;
  transition-delay: 0.3s;
}

[data-contract-loading=true] {
  #overlay {
    display: none;
    pointer-events: none;
  }
}

[data-dialog-pointer-events=true] {
  #overlay {
    pointer-events: all;
    background: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(10px);
    transition: 0.5s;
  }

  dialog {
    opacity: 1;
    transition: 0.5s;
    transition-delay: 0.3s;
  }
}
